var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "upload-btn" }, [
    _c("input", {
      ref: "uploadFile",
      attrs: {
        id: `${_vm._uid}uploadFile`,
        type: "file",
        name: _vm.name,
        accept: _vm.accept,
        multiple: _vm.multiple,
      },
      on: { change: _vm.fileChanged },
    }),
    _c(
      "label",
      {
        directives: [
          {
            name: "ripple",
            rawName: "v-ripple",
            value: _vm.ripple,
            expression: "ripple",
          },
        ],
        class: `v-btn ${_vm.classes}${_vm.color} ${_vm.labelClass} upload-btn`,
        style: { maxWidth: _vm.maxWidth, width: _vm.fixedWidth || "auto" },
        attrs: {
          id: `label${_vm._uid + "uploadFile"}`,
          for: `${_vm._uid}uploadFile`,
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "v-btn__content",
            staticStyle: { "max-width": "100%" },
          },
          [
            _vm._t("icon-left"),
            _c("span", [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.icon
                      ? ""
                      : _vm.noTitleUpdate
                      ? _vm.title
                      : _vm.uTitle || _vm.title
                  ) +
                  "\n      "
              ),
            ]),
            _vm._t("icon"),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }